@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~material-icons/iconfont/material-icons.css';
@import 'ngx-toastr/toastr';

body {
  margin: 0;
  background-color: #F6F7F9;
  overflow-y: scroll;
}


.validation {
  color: red;
}

.disabled-select .ng-select-container {
  background-color: #ededed;
  color: #c0c1c1;
  font-weight: 300;
  font-size: 1rem;
}

.ng-select.ng-select-single .ng-select-container, .ng-select .ng-select-container  {
  height: 43px;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.ng-placeholder {
  color: #A4AEBF!important;
  top: 25%!important;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #eee;
  border: 1px solid #ced4da;
}

.popover {max-width: 755px !important;}

.tooltip-inner {
  max-width: unset !important;
}
